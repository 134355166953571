.radioGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    padding-left: 30px;
  }
  
  .radioOption {
    flex-basis: calc(50% - 10px); /* subtracting half of the gap to ensure proper alignment */
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #023047;
    cursor: pointer;
  }
  
/* Hide the default radio button */
.radioOption input[type="radio"] {
  appearance: none; /* Removes default appearance for modern browsers */
  -moz-appearance: none; /* For Firefox */
  -webkit-appearance: none; /* For older versions of Safari */
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  cursor: pointer;
}
  
.radioOption input[type="radio"]:checked {
  background-color: #6e4c0d; /* The desired selection color */
  border: 1px solid #6e4c0d; /* Set border color to match background for a seamless look */
}

.radioOption input[type="radio"]:checked::after {
  content: "";
  display: block;
  width: 14px; /* Adjusted size */
  height: 14px; /* Adjusted size */
  border-radius: 50%;
  background-color: #edc531; /*  circle in the middle */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  /* Customize the radio buttons to match the theme if needed */
  .radioOption input[type="radio"]:checked + span {
    color: #18791f; /* Change this to match your theme if needed */
  }

.radioGroupWrapper {
    margin-bottom: 20px; /* Adjust spacing as needed */
  }
  
  .radioGroupCaption {
    font-size: 18px; /* Adjust font size as needed */
    font-weight: bold;
    margin-bottom: 10px; /* Adjust spacing as needed */
    color: #023047;
    text-align: center;
  }